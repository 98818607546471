<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-format-list-checks</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <!-- :error-messages="nomeErrors" 
                              required
                  @input="$v.tarefaLocal.nome_tarefa.$touch()"
                  @blur="$v.tarefaLocal.nome_tarefa.$touch()"-->

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="1" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    :value="tarefaLocal.id"
                    label="ID"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="10" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="tarefaLocal.titulo"
                    label="Titulo"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    :value="obterUsuario(tarefaLocal.cria_usuario)"
                    label="Criador"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="tarefaLocal.cria_datahora"
                    label="Criação"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col class="ml-6" cols="auto" md="2" align-self="center">
                  <!-- <v-chip :color="statusCor" text-color="white" small>
                    {{ statusTexto }}
                  </v-chip> -->
                  <v-chip
                    :color="
                      pegaCorStatus(
                        tarefaLocal.aguardando,
                        tarefaLocal.andamento,
                        tarefaLocal.concluida,
                        tarefaLocal.arquivada
                      )
                    "
                    text-color="white"
                    small
                  >
                    {{
                      metStatus(
                        tarefaLocal.aguardando,
                        tarefaLocal.andamento,
                        tarefaLocal.concluida,
                        tarefaLocal.arquivada
                      )
                    }}
                  </v-chip>
                </v-col>
                <v-col cols="auto" md="1" align-self="center">
                  <v-checkbox
                    dense
                    v-model="tarefaLocal.prioridade"
                    label="Prioridade"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-select
                    dense
                    hide-details="auto"
                    v-model="tarefaLocal.responsavel"
                    label="Responsavel"
                    :items="usuarioLista"
                    item-text="usuario"
                    item-value="id"
                    outlined
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-select
                    dense
                    hide-details="auto"
                    v-model="tarefaLocal.observador"
                    label="Observador"
                    :items="usuarioLista"
                    item-text="usuario"
                    item-value="id"
                    outlined
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    type="date"
                    v-model="tarefaLocal.prazo"
                    label="Prazo"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <v-textarea
                    onkeydown="if(event.keyCode===9){var v=this.value,s=this.selectionStart,e=this.selectionEnd;this.value=v.substring(0, s)+'\t'+v.substring(e);this.selectionStart=this.selectionEnd=s+1;return false;}"
                    hide-details="auto"
                    v-model="tarefaLocal.texto"
                    label="Tarefa"
                    rows="10"
                  ></v-textarea>
                </v-col>
              </v-row> -->

              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <quill-editor
                    class="mt-4"
                    ref="myQuillEditor"
                    v-model="tarefaLocal.texto"
                  >
                  </quill-editor>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-col>
              <v-row>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-4 mr-4 mb-4"
                      color="green darken-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="submit"
                    >
                      mdi-check-circle
                    </v-icon>
                  </template>
                  <span>Salvar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-4 mb-4"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                      @click="metVoltar()"
                    >
                      mdi-backspace
                    </v-icon>
                  </template>
                  <span>Cancelar</span>
                </v-tooltip>

                <!-- <v-icon
                  color="indigo"
                  class="mr-4 mb-4"
                  @click="metVoltar()"
                  v-b-tooltip.hover="{
                    placement: 'top',
                    title: 'Retornar',
                  }"
                >
                  mdi-arrow-left-circle-outline
                </v-icon> -->
                <v-spacer></v-spacer>
                <span
                  v-if="
                    !tarefaLocal.arquivada &&
                    metMostraPar(tarefaLocal.responsavel, tarefaLocal.andamento)
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-4 mb-4"
                        color="pink"
                        v-bind="attrs"
                        v-on="on"
                        @click="metTarefaParar(tarefaLocal)"
                      >
                        mdi-stop-circle-outline
                      </v-icon>
                    </template>
                    <span>Parar</span>
                  </v-tooltip>
                </span>

                <span
                  v-if="
                    !tarefaLocal.arquivada &&
                    metMostraCome(
                      tarefaLocal.responsavel,
                      tarefaLocal.aguardando
                    )
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-4 mb-4"
                        color="indigo"
                        v-bind="attrs"
                        v-on="on"
                        @click="metTarefaComecar(tarefaLocal)"
                      >
                        mdi-play-circle-outline
                      </v-icon>
                    </template>
                    <span>Começar</span>
                  </v-tooltip>
                </span>

                <span
                  v-if="
                    !tarefaLocal.arquivada &&
                    metMostraConc(
                      tarefaLocal.cria_usuario,
                      tarefaLocal.responsavel,
                      tarefaLocal.andamento
                    )
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-4 mb-4"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="metTarefaConcluir(tarefaLocal)"
                      >
                        mdi-checkbox-marked-outline
                      </v-icon>
                    </template>
                    <span>Concluir</span>
                  </v-tooltip>
                </span>

                <span
                  v-if="
                    !tarefaLocal.arquivada &&
                    metMostraAbr(
                      tarefaLocal.cria_usuario,
                      tarefaLocal.responsavel,
                      tarefaLocal.concluida
                    )
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-4 mb-4"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="metTarefaAbrir(tarefaLocal)"
                      >
                        mdi-skip-backward-outline
                      </v-icon>
                    </template>
                    <span>Abrir</span>
                  </v-tooltip>
                </span>

                <span v-if="!tarefaLocal.arquivada">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="metMostraArq(tarefaLocal.cria_usuario)"
                        class="mr-4 mb-4"
                        color="brown"
                        v-bind="attrs"
                        v-on="on"
                        @click="metTarefaArquivar(tarefaLocal)"
                      >
                        mdi-archive-outline
                      </v-icon>
                    </template>
                    <span>Arquivar</span>
                  </v-tooltip>
                </span>

                <span v-if="tarefaLocal.arquivada">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="metMostraReciclar(tarefaLocal.cria_usuario)"
                        class="mr-4 mb-4"
                        color="orange darken-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="metTarefaReciclar(tarefaLocal)"
                      >
                        mdi-recycle
                      </v-icon>
                    </template>
                    <span>Reciclar</span>
                  </v-tooltip>
                </span>
              </v-row>
            </v-col>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";
import MailMixin from "@/mixins/MailMixin";

const { mapState, mapActions } = createNamespacedHelpers("utilTarefas");

export default {
  name: "UtilTarefasForm",
  mixins: [validationMixin, DatasMixin, MailMixin],
  // validations: {
  //   tarefaLocal: {
  //     nome_tarefa: { required },
  //     email: { required, email },
  //     tarefa: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    tituloForm: "",
    tarefaLocal: {},
    comprasIconDisable: false,
    // statusCor: undefined,
    // statusTexto: undefined,
  }),

  computed: {
    ...mapState(["tarefaSelecionado", "listaUsuarios"]),
    // nomeErrors() {
    //   const errors = [];
    //   if (!this.$v.tarefaLocal.nome_tarefa.$dirty) return errors;
    //   !this.$v.tarefaLocal.nome_tarefa.required &&
    //     errors.push("Nome requerido.");
    //   return errors;
    // },
    // emailErrors() {
    //   const errors = [];
    //   if (!this.$v.tarefaLocal.email.$dirty) return errors;
    //   !this.$v.tarefaLocal.email.email && errors.push("E-mail inválido");
    //   !this.$v.tarefaLocal.email.required && errors.push("E-mail requerido");
    //   return errors;
    // },
    // tarefaErrors() {
    //   const errors = [];
    //   if (!this.$v.tarefaLocal.tarefa.$dirty) return errors;
    //   !this.$v.tarefaLocal.tarefa.required &&
    //     errors.push("Usuário requerido.");
    //   return errors;
    // },
    // senhaErrors() {
    //   const errors = [];
    //   if (!this.$v.tarefaLocal.senha.$dirty) return errors;
    //   !this.$v.tarefaLocal.senha.required && errors.push("Senha requerida.");
    //   return errors;
    // },
    usuarioLista() {
      return this.$store.state.utilTarefas.listaUsuarios;
    },
  },

  watch: {
    tarefaSelecionado(tarefaNovo) {
      this.sincronizar(tarefaNovo);
    },
  },

  created() {
    this.sincronizar(this.tarefaSelecionado);

    if (this.tarefaSelecionado) {
      this.tituloForm = "Editar Tarefa";
    } else {
      this.tituloForm = "Novo Tarefa";
    }
    this.metDatasDB();
    if (this.tarefaSelecionado) {
      this.desAvisoMod();
      this.desAvisoNova();
    }
    // this.metStatusCor();
    // this.metStatusTexto();
  },

  methods: {
    ...mapActions([
      "selecionarTarefa",
      "resetarTarefa",
      "criarTarefa",
      "editarTarefa",
      "resetarErro",
    ]),

    async desAvisoMod() {
      var atualizaBanco = false;
      const usuarioId = this.$store.state.login.usuario.id;
      const tarefaIn = this.tarefaLocal;

      if (usuarioId == tarefaIn.cria_usuario && tarefaIn.criadorAvisoMod == 1) {
        tarefaIn.criadorAvisoMod = 0;
        atualizaBanco = true;
      }
      if (usuarioId == tarefaIn.responsavel && tarefaIn.responAvisoMod == 1) {
        tarefaIn.responAvisoMod = 0;
        atualizaBanco = true;
      }
      if (usuarioId == tarefaIn.observador && tarefaIn.obsAvisoMod == 1) {
        tarefaIn.obsAvisoMod = 0;
        atualizaBanco = true;
      }

      if (atualizaBanco) {
        tarefaIn.prazo = this.mixDataFormD(tarefaIn.prazo);
        tarefaIn.cria_datahora = this.mixDataFormD(tarefaIn.cria_datahora);
        tarefaIn.mod_datahora = null;

        await this.editarTarefa({
          tarefa: tarefaIn,
          licenca: this.$store.state.login.licenca,
        });
      }
    },

    async desAvisoNova() {
      var atualizaBanco = false;
      const usuarioId = this.$store.state.login.usuario.id;
      const tarefaIn = this.tarefaLocal;

      if (usuarioId == tarefaIn.responsavel && tarefaIn.responAvisoNova == 1) {
        tarefaIn.responAvisoNova = 0;
        atualizaBanco = true;
      }
      if (usuarioId == tarefaIn.observador && tarefaIn.obsAvisoNova == 1) {
        tarefaIn.obsAvisoNova = 0;
        atualizaBanco = true;
      }

      if (atualizaBanco) {
        tarefaIn.prazo = this.mixDataFormD(tarefaIn.prazo);
        tarefaIn.cria_datahora = this.mixDataFormD(tarefaIn.cria_datahora);
        tarefaIn.mod_datahora = null;

        await this.editarTarefa({
          tarefa: tarefaIn,
          licenca: this.$store.state.login.licenca,
        });
      }
    },

    metMostraArq(dono) {
      if (dono == this.$store.state.login.usuario.id) {
        return true;
      }
      return false;
    },

    metMostraReciclar(dono) {
      if (dono == this.$store.state.login.usuario.id) {
        return true;
      }
      return false;
    },

    metMostraCome(resp, agua) {
      if (resp == this.$store.state.login.usuario.id && agua == 1) {
        return true;
      }
      return false;
    },

    metMostraPar(resp, and) {
      if (resp == this.$store.state.login.usuario.id && and == 1) {
        return true;
      }
      return false;
    },

    metMostraConc(dono, resp, and) {
      if (
        (dono == this.$store.state.login.usuario.id ||
          resp == this.$store.state.login.usuario.id) &&
        and == 1
      ) {
        return true;
      }
      return false;
    },

    metMostraAbr(dono, resp, conc) {
      if (
        (dono == this.$store.state.login.usuario.id ||
          resp == this.$store.state.login.usuario.id) &&
        conc == 1
      ) {
        return true;
      }
      return false;
    },

    async metTarefaArquivar(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.arquivada = 1;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa Arquivada!",
      });
      this.statusTexto = "Arquivada";
      this.statusCor = "blue-grey darken-1";
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Arquivada"
      );
    },

    async metTarefaReciclar(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.arquivada = 0;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa Reciclada!",
      });
      this.statusTexto = "Aguardando";
      this.statusCor = "red darken-2";
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Reciclada"
      );
    },

    async metTarefaComecar(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.andamento = 1;
      tarefaTemp.aguardando = 0;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa iniciada",
      });
      this.statusTexto = "Andamento";
      this.statusCor = "blue darken-1";
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Iniciada"
      );
    },

    async metTarefaParar(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.andamento = 0;
      tarefaTemp.aguardando = 1;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa parada",
      });
      this.statusTexto = "Aguardando";
      this.statusCor = "red darken-2";
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Parada"
      );
    },

    async metTarefaConcluir(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.andamento = 0;
      tarefaTemp.concluida = 1;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });
      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa concluída",
      });
      this.statusTexto = "Concluida";
      this.statusCor = "green darken-2";
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Concluida"
      );
    },

    async metTarefaAbrir(item) {
      // eslint-disable-next-line
      var tarefaTemp = Object.assign({}, item);
      tarefaTemp.concluida = 0;
      tarefaTemp.aguardando = 1;

      tarefaTemp.prazo = this.mixDataFormD(tarefaTemp.prazo);
      tarefaTemp.cria_datahora = this.mixDataFormD(tarefaTemp.cria_datahora);
      tarefaTemp.mod_datahora = null;

      await this.editarTarefa({
        tarefa: tarefaTemp,
        licenca: this.$store.state.login.licenca,
      });

      this.$root.snackbar.show({
        type: "success",
        message: "Tarefa aberta",
      });
      this.statusTexto = "Aguardando";
      this.statusCor = "red darken-2";
      this.metEmail(
        tarefaTemp.id,
        tarefaTemp.titulo,
        this.obterEmail(tarefaTemp.cria_usuario),
        this.obterEmail(tarefaTemp.responsavel),
        this.obterEmail(tarefaTemp.observador),
        "Aberta"
      );
    },

    // metStatusTexto() {
    //   this.statusTexto = "Status";
    //   if (this.tarefaLocal.arquivada == 1) {
    //     this.statusTexto = "Arquivada";
    //   } else if (this.tarefaLocal.concluida == 1) {
    //     this.statusTexto = "Concluida";
    //   } else if (this.tarefaLocal.andamento == 1) {
    //     this.statusTexto = "Andamento";
    //   } else if (this.tarefaLocal.aguardando == 1) {
    //     this.statusTexto = "Aguardando";
    //   }
    // },

    // metStatusCor() {
    //   this.statusCor = "";
    //   if (this.tarefaLocal.arquivada == 1) {
    //     this.statusCor = "blue-grey darken-1";
    //   } else if (this.tarefaLocal.concluida == 1) {
    //     this.statusCor = "green darken-2";
    //   } else if (this.tarefaLocal.andamento == 1) {
    //     this.statusCor = "blue darken-1";
    //   } else if (this.tarefaLocal.aguardando == 1) {
    //     this.statusCor = "red darken-2";
    //   }
    // },

    metStatus(agua, anda, conc, arq) {
      if (arq == 1) {
        return "Arquivada";
      } else if (conc == 1) {
        return "Concluida";
      } else if (anda == 1) {
        return "Andamento";
      } else if (agua == 1) {
        return "Aguardando";
      }
      return "Status";
    },

    pegaCorStatus(agua, anda, conc, arq) {
      if (arq == 1) {
        return "blue-grey darken-1";
      } else if (conc == 1) {
        return "green darken-2";
      } else if (anda == 1) {
        return "blue darken-1";
      } else if (agua == 1) {
        return "red darken-2";
      }
      this.varStatus = "Status";
      return "";
    },

    obterUsuario(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].usuario;
      } else {
        const ret = "";
        return ret;
      }
    },

    obterEmail(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].email;
      } else {
        const ret = "";
        return ret;
      }
    },

    selecionarTarefaEdicao(tarefa) {
      this.selecionarTarefa({ tarefa });
    },
    metDatasForm() {
      this.tarefaLocal.cria_datahora = this.mixDataFormD(
        this.tarefaLocal.cria_datahora
      );
      this.tarefaLocal.mod_datahora = this.mixDataFormD(
        this.tarefaLocal.mod_datahora
      );
      this.tarefaLocal.prazo == "" ? (this.tarefaLocal.prazo = null) : null;
    },
    metDatasDB() {
      this.tarefaLocal.cria_datahora = this.mixDataDBD(
        this.tarefaLocal.cria_datahora
      );
      this.tarefaLocal.mod_datahora = this.mixDataDBD(
        this.tarefaLocal.mod_datahora
      );
      this.tarefaLocal.prazo = this.mixDataDBDCal(this.tarefaLocal.prazo);
    },

    marcaAvisoMod(id) {
      if (id == this.tarefaLocal.cria_usuario) {
        this.tarefaLocal.criadorAvisoMod = 0;
        id == this.tarefaLocal.responsavel
          ? (this.tarefaLocal.responAvisoMod = 0)
          : (this.tarefaLocal.responAvisoMod = 1);
        id == this.tarefaLocal.observador
          ? (this.tarefaLocal.obsAvisoMod = 0)
          : (this.tarefaLocal.obsAvisoMod = 1);
      } else if (id == this.tarefaLocal.responsavel) {
        this.tarefaLocal.responAvisoMod = 0;
        id == this.tarefaLocal.cria_usuario
          ? (this.tarefaLocal.criadorAvisoMod = 0)
          : (this.tarefaLocal.criadorAvisoMod = 1);
        id == this.tarefaLocal.observador
          ? (this.tarefaLocal.obsAvisoMod = 0)
          : (this.tarefaLocal.obsAvisoMod = 1);
      } else if (id == this.tarefaLocal.observador) {
        this.tarefaLocal.obsAvisoMod = 0;
        id == this.tarefaLocal.responsavel
          ? (this.tarefaLocal.responAvisoMod = 0)
          : (this.tarefaLocal.responAvisoMod = 1);
        id == this.tarefaLocal.cria_usuario
          ? (this.tarefaLocal.criadorAvisoMod = 0)
          : (this.tarefaLocal.criadorAvisoMod = 1);
      }
    },

    async salvarTarefa() {
      switch (this.tarefaSelecionado) {
        case undefined:
          this.tarefaLocal.cria_usuario = this.$store.state.login.usuario.id;
          this.tarefaLocal.cria_datahora = this.mixDataAgoraBR();
          this.tarefaLocal.aguardando = 1;
          this.tarefaLocal.responAvisoNova = 1;
          this.tarefaLocal.obsAvisoNova = 1;
          this.marcaAvisoMod(this.$store.state.login.usuario.id);
          this.metDatasForm();
          await this.criarTarefa({
            tarefa: this.tarefaLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.metDatasDB();
          if (this.$store.state.utilTarefas.erro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.utilTarefas.erro,
            });
            this.resetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Tarefa Criada!",
            });
            this.metEmail(
              this.tarefaLocal.id,
              this.tarefaLocal.titulo,
              this.obterEmail(this.tarefaLocal.cria_usuario),
              this.obterEmail(this.tarefaLocal.responsavel),
              this.obterEmail(this.tarefaLocal.observador),
              "Criada"
            );
          }
          break;
        default:
          this.tarefaLocal.mod_usuario = this.$store.state.login.usuario.id;
          this.tarefaLocal.mod_datahora = this.mixDataAgoraBR();
          this.marcaAvisoMod(this.$store.state.login.usuario.id);
          this.metDatasForm();
          await this.editarTarefa({
            tarefa: this.tarefaLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.metDatasDB();
          if (this.$store.state.utilTarefas.erro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.utilTarefas.erro,
            });
            this.resetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Tarefa Salva!",
            });
            this.metEmail(
              this.tarefaLocal.id,
              this.tarefaLocal.titulo,
              this.obterEmail(this.tarefaLocal.cria_usuario),
              this.obterEmail(this.tarefaLocal.responsavel),
              this.obterEmail(this.tarefaLocal.observador),
              "Modificada"
            );
          }
      }
      //this.resetar();
    },

    sincronizar(novoTarefa) {
      this.tarefaLocal = Object.assign(
        {},
        novoTarefa || {
          id: null,
          cria_datahora: null,
          mod_datahora: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarTarefa();
      // this.$router.back();
    },
    metClear() {},
    metVoltar() {
      this.$router.back();
    },

    metEmail(id, titulo, emailCria, emailResp, emailObs, acao) {
      this.mixEnviaEmail(
        "Tarefas - Clomos<rotnet@rotnet.com.br",
        emailCria + "," + emailResp + "," + emailObs,
        "",
        "Tarefa " + id + " " + acao,
        "Tarefa: " +
          id +
          " " +
          acao +
          "\n" +
          "Titulo: " +
          titulo +
          "\n" +
          acao +
          " por: " +
          this.$store.state.login.usuario.nome +
          "\n\n" +
          "Clomos"
      );
    },
  },
};
</script>
