import moment from "moment";

export default {
  methods: {
    // dataForm(data) {
    //   switch (data) {
    //     case "0000-00-00":
    //       return "0000-00-00";
    //     case "00-00-0000":
    //       return "0000-00-00";
    //     case "":
    //       return "0000-00-00";
    //     case null:
    //       return "0000-00-00";
    //     case undefined:
    //       return "0000-00-00";
    //     case "Invalid date":
    //       return "0000-00-00";
    //     default:
    //       return moment(data, "DD-MM-YYYY").format("YYYY-MM-DD");
    //   }
    // },

    // dataUTCForm(data) {
    //   switch (data) {
    //     case "0000-00-00":
    //       return "0000-00-00";
    //     case "00-00-0000":
    //       return "0000-00-00";
    //     case "":
    //       return "0000-00-00";
    //     case "Invalid date":
    //       return "0000-00-00";
    //     default:
    //       return moment
    //         .utc(data, "DD-MM-YYYY HH:MM:ss")
    //         .format("YYYY-MM-DD HH:MM:ss");
    //   }
    // },

    // dataUTCDB(data) {
    //   switch (data) {
    //     case null:
    //       return "";
    //     case undefined:
    //       return "";
    //     case "":
    //       return "";
    //     case "Invalid date":
    //       return "";
    //     default:
    //       return moment.utc(data).format("DD-MM-YYYY HH:mm:SS");
    //   }
    // },

    // dataBRISO(data) {
    //   switch (data) {
    //     case undefined:
    //       return null;
    //     case "0000-00-00":
    //       return null;
    //     case "00-00-0000":
    //       return null;
    //     case "":
    //       return null;
    //     case "Invalid date":
    //       return null;
    //     default:
    //       return moment
    //         .utc(data, "DD-MM-YYYY HH:mm:SS")
    //         .format("YYYY-MM-DD HH:mm:SS");
    //   }
    // },

    // dataISOBR(data) {
    //   switch (data) {
    //     case null:
    //       return "";
    //     case undefined:
    //       return "";
    //     case "0000-00-00":
    //       return "";
    //     case "00-00-0000":
    //       return "";
    //     case "":
    //       return "";
    //     case "Invalid date":
    //       return "";
    //     default:
    //       return moment
    //         .utc(data, "YYYY-MM-DD HH:mm:SS")
    //         .format("DD-MM-YYYY HH:mm:SS");
    //   }
    // },

    // dataBRISOd(data) {
    //   switch (data) {
    //     case null:
    //       return undefined;
    //     case undefined:
    //       return null;
    //     case "0000-00-00":
    //       return null;
    //     case "00-00-0000":
    //       return null;
    //     case "":
    //       return null;
    //     case "Invalid date":
    //       return null;
    //     default:
    //       return moment.utc(data, "DD-MM-YYYY HH:mm:SS").format("YYYY-MM-DD");
    //   }
    // },

    // dataISOBRd(data) {
    //   switch (data) {
    //     case null:
    //       return "";
    //     case undefined:
    //       return "";
    //     case "0000-00-00":
    //       return "";
    //     case "00-00-0000":
    //       return "";
    //     case "":
    //       return "";
    //     case "Invalid date":
    //       return "";
    //     default:
    //       return moment.utc(data, "YYYY-MM-DD HH:mm:SS").format("DD/MM/YYYY");
    //   }
    // },

    // dataISOISOd(data) {
    //   // console.log("dt -", data);
    //   switch (data) {
    //     case null:
    //       return null;
    //     case undefined:
    //       return null;
    //     case "0000-00-00":
    //       return null;
    //     case "00-00-0000":
    //       return null;
    //     case "":
    //       return null;
    //     case "Invalid date":
    //       return null;
    //     default:
    //       return moment.utc(data, "YYYY-MM-DD HH:mm:SS").format("YYYY-MM-DD");
    //   }
    // },

    // dataISOISOdh(data) {
    //   // console.log("dt -", data);
    //   switch (data) {
    //     case null:
    //       return null;
    //     case undefined:
    //       return null;
    //     case "0000-00-00":
    //       return null;
    //     case "00-00-0000":
    //       return null;
    //     case "":
    //       return null;
    //     case "Invalid date":
    //       return null;
    //     default:
    //       return moment
    //         .utc(data, "YYYY-MM-DD HH:mm:ss")
    //         .format("YYYY-MM-DD HH:mm:ss");
    //   }
    // },

    // Retorna data formato DD/MM/YYYY
    mixDataAgoraBR() {
      var data = new Date();
      data.setHours(data.getHours() - 3);
      data = moment.utc(data).format("DD/MM/YYYY");
      return data;
    },

    // Retorna data formato YYYY-MM-DD
    mixDataFormD(data) {
      // data = "22-03-2022 06:00:00";
      // console.log("1F) ", data);

      if (data == "") {
        return null;
      }

      if (data == null) {
        return null;
      }

      if (data.includes("T") && data.includes("Z")) {
        var dt = data.replace(/T/, " ").replace(/\..+/, "");
        // console.log("4F) ", dt);
        return dt;
      }

      var dataAno = data.split("-", 1);
      if (dataAno[0].length == 4) {
        // console.log("5F) ", data);
        return data;
      }

      var dataDia = data.split("/", 1);
      if (dataDia[0].length == 2) {
        // eslint-disable-next-line
        var dt = data.replace(/\//g, "-");
        var dtPart = dt.split(" ");
        var res = dtPart[0].split("-").reverse().join("-");
        if (dtPart[1] != undefined) {
          res = res + " " + dtPart[1];
        }
        // console.log("6F) ", res);
        return res;
      }

      // console.log("FIM) ", "DATA NAO ENQUANDROU FORM", data);
    },

    // Retorna data formato DD-MM-YYYY
    mixDataDBD(data) {
      // data = "22-03-2022 06:00:00";
      // console.log("1D) ", data);

      if (data == "") {
        return null;
      }

      if (data == null) {
        return null;
      }
      if (data.includes("T") && data.includes("Z")) {
        var dt = data
          .replace(/T/, " ")
          .replace(/\..+/, "")
          // eslint-disable-next-line
          .replace(/\-/g, "/");
        var dtPart = dt.split(" ");
        var res = dtPart[0].split("/").reverse().join("/");
        // console.log("4D) ", res);
        return res;
      }

      var dataDia = data.split("-", 1);
      if (dataDia[0].length == 4) {
        // eslint-disable-next-line
        var dt = data.replace(/\-/g, "/");
        dtPart = dt.split(" ");
        res = dtPart[0].split("/").reverse().join("/");
        if (dtPart[1] != undefined) {
          res = res + " " + dtPart[1];
        }
        // console.log("5D) ", res);
        return res;
      }

      // console.log("FIM) ", "DATA NAO ENQUANDROU BD", data);
    },

    // Retorna data formato DD-MM-YYYY HH:MM:SS
    mixDataDBT(data) {
      // data = "22-03-2022 06:00:00";
      // data = "2022-08-18T14:17:50.000Z"
      // console.log("1D) ", data);

      if (data == "") {
        return null;
      }

      if (data == null) {
        return null;
      }
      if (data.includes("T") && data.includes("Z")) {
        var dt = new Date(data);
        var res = dt.toLocaleDateString() + " " + dt.toLocaleTimeString();
        // console.log("4D) ", dt.toLocaleDateString(), dt.toLocaleTimeString());
        return res;
      }

      // var dataDia = data.split("-", 1);
      // if (dataDia[0].length == 4) {
      //   // eslint-disable-next-line
      //   var dt = data.replace(/\-/g, "/");
      //   dtPart = dt.split(" ");
      //   res = dtPart[0].split("/").reverse().join("/");
      //   if (dtPart[1] != undefined) {
      //     res = res + " " + dtPart[1];
      //   }
      //   // console.log("5D) ", res);
      //   return res;
      // }

      // console.log("FIM) ", "DATA NAO ENQUANDROU BD", data);
    },

    // Retorna formato YYYY-MM-DD para quando no form tem um calendar
    mixDataDBDCal(data) {
      // data = "22-03-2022 06:00:00";
      // console.log("1C) ", data);

      if (data == "") {
        // console.log("2C) ", "null");
        return null;
      }

      if (data == null) {
        // console.log("3C) ", "null");
        return null;
      }

      if (data.includes("T") && data.includes("Z")) {
        var dt = data.replace(/T/, " ").replace(/\..+/, "");
        // eslint-disable-next-line
        // .replace(/\-/g, "/");
        var dtPart = dt.split(" ");
        var res = dtPart[0].split("/").reverse().join("/");
        // console.log("4C) ", res);
        return res;
      }

      var dataAno = data.split("-", 1);
      if (dataAno[0].length == 4) {
        // console.log("5C) ", data);
        return data;
      }

      // console.log("FIM) ", "DATA NAO ENQUANDROU DBCal", data);
    },
  },
};

// console.log(new Intl.DateTimeFormat("pt-BR").format(date));
