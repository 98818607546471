import apiClient from "@/services/axios";

export default {
  methods: {
    postMail(email) {
      return apiClient.post("/mailSend/clomosenviar", email);
    },
    async enviaEmail({ email }) {
      try {
        const response = await this.postMail(email);
        this.$root.snackbar.show({
          type: "info",
          message: response.data.response,
        });
      } catch (error) {
        this.$root.snackbar.show({
          type: "danger",
          message: error,
        });
      }
    },
    mixEnviaEmail(from, to, replayTo = "", subject, text) {
      if (this.$store.state.login.licenca == "dev") {
        to = "sysnext@sysnext.com.br";
      }
      var email = {
        from: from,
        to: to,
        replayTo: replayTo,
        subject: subject,
        text: text,
      };
      this.enviaEmail({ email });
    },
  },
};
